// 100 Extra Light or Ultra Light;
// 200 Light or Thin; 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black

@include font-face('Lato', '../fonts/Lato/LatoRegular/LatoRegular', null, null,  woff2 woff );
@include font-face('Lato', '../fonts/Lato/LatoBold/LatoBold', bold, null,  woff2 woff);
@include font-face('Lato', '../fonts/Lato/LatoLight/LatoLight', 300, null, woff2 woff);

