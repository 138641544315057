img.lazy{
	width: 100%;
	display: block;
}

.has-danger{

	.invalid-feedback{
		display: block;
	}

	.form-control{
		@extend .is-invalid;
	}
}

.has-success{
	.form-control{
		@extend .is-valid;
	}
}

.section{
	padding-top: 100px;
	padding-bottom: 80px;
}
h1{
	text-align:center;
	font-size: 74px;
	font-weight: bold;
	margin-bottom: 20px;
	line-height: 1;
	color: #fff;
	@include media-breakpoint-down(md){
		font-size:54px;
		
	}
	@include media-breakpoint-down(sm){
		font-size:36px;
		margin-bottom: 14px;
	}
	@include media-breakpoint-down(xs){
		font-size:24px;
	}
}

.section-title__wrap{
	text-align: center;
	margin-bottom: 60px;
}
.section-title__top{
	position: relative;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	display: inline-block;
	margin: 0 auto;
	margin-bottom: 10px;
	display: none;
	&::before{
		position: absolute;
		width: 9px;
		content: '';
		border: 1px dashed #333;
		top: 0;
		bottom: 0;
		left: -20px;
		height: 1px;
		margin: auto;
	}
	&::after{
		position: absolute;
		width: 9px;
		content: '';
		border: 1px dashed #333;
		top: 0;
		bottom: 0;
		right: -20px;
		height: 1px;
		margin: auto;
	}
}

.section-title{
	font-size: 36px;
    line-height: 1.1;
    font-weight: 700; 
	color: #333;
	max-width: 540px;
	margin: 0 auto;
	margin-bottom: 10px;
	@include media-breakpoint-down(xs){
		font-size: 24px;
	}
}

.section-descr{
	font-size: 16px;
    line-height: 1.2;
	font-weight: 400;
	margin: 0 auto;
    margin-bottom: 40px;
	color: #333333;
	max-width: 620px;
}
.section-title__wrap--white{
	.section-title__top{
		color:#fff;
		&::before{
			border: 1px dashed #fff;
		}
		&::after{
			border: 1px dashed #fff;
		}
	}
	.section-title{
		color:#fff;
	}
	.section-descr{
		color:#fff;
	}
}

.btn{
	padding: 16px 35px;
	color: #fff;
    transition:  0.3s;
    margin-bottom: 0;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 100px;
	font-weight: 500;
	text-transform: uppercase;	
}

.btn-primary--white{
	border: 1px solid #eee;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #222;
    background: #fff;
	text-transform: uppercase;
	&:hover{
		background-color: #f38017;
		border-color: #f38017;
		opacity: 0.9;
    	box-shadow: 0px 1px 4px 0px #333;
	}
}
.get-in-touch__form__btn{
	text-align: center;
}
.btn-dark{
	border-radius: 0;
	padding: 7px 12px;
	color:#fff;
	text-transform: capitalize;
	font-size:16px;
	height: 45px;
	width: 240px;
	border:none;
	background-color:#111111;
	transition: 0.3s;
	&:hover{
		background-color:#269AD6 ;
	}
}
/*
* Flip card
*/

.flip-card{
	
	perspective: 800px;
	position: relative;

	&.flip-card--x, &{
		.flip-card__back{			
			transform: rotateX(-180deg);
		}

		// &:hover{
		// 	.flip-card__wrap{
		// 		transform: rotateX(-180deg);
		// 	}
		// }
	}

	&.flip-card--y{
		.flip-card__back{			
			transform: rotateY(-180deg);
		}

		// &:hover{
		// 	.flip-card__wrap{
		// 		transform: rotateY(-180deg);
		// 	}
		// }
	}

}

.flip-card__wrap{
	transform-style: preserve-3d;
	transition: transform 1s;
}

.flip-card__front{
	display: block;
	width: 100%;
	backface-visibility: hidden;
	background-color: inherit;
	
	z-index: 10;
}

.flip-card__back{
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	background-color: inherit;
}